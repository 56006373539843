import React from 'react'
import { Row, Container, Col } from 'react-bootstrap'

import { TopStepper } from '../components/Nav/TopStepper'

import { SideStepperLayoutPropsType } from './types'

export const TopStepperLayout: React.FC<SideStepperLayoutPropsType> = ({
  children,
  containerFluid = 'lg',
  title,
  customMenu,
  ...topStepperProps
}) => {
  return (
    <Container fluid={containerFluid} className="top-nav-layout">
      {!!title && (
        <Container fluid="lg" className={containerFluid === 'lg' ? 'px-0' : undefined}>
          <Row className="mt-sm-5 mt-3 mx-sm-3 mx-lg-5">
            <Col>
              <h3 className="text-nowrap">{title}</h3>
            </Col>
            {!!customMenu && <Col>{customMenu}</Col>}
          </Row>
        </Container>
      )}
      <Container fluid="lg" className={containerFluid === 'lg' ? 'px-0' : undefined}>
        <Row className="my-2 mx-sm-3 mx-lg-5">
          <Col xs={12}>
            <TopStepper {...topStepperProps} />
          </Col>
        </Row>
      </Container>
      <Row className="mb-3 py-2 mx-sm-3 mx-lg-5 p-sm-3 p-md-4 px-lg-5 shadow-sm bg-white rounded main-card">
        <Col xs={12} className="h-100 overflow-scroll position-relative">
          {children}
        </Col>
      </Row>
    </Container>
  )
}
