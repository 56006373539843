import { ContractSettingType } from '@/components/features/ContractSetting/types'
import { statusOptions } from '@/components/features/CustomerContract/types'

export type CustomerContractsNewPropsType = {
  contractSetting?: Omit<ContractSettingType, 'status'>
  folderId?: string
}

export type CustomerContractsEditPropsType = {
  contractSetting?: Omit<ContractSettingType, 'status' | 'approverSettings'>
  readonly id: string
  folderId?: string
  currentUserId: string
  permissionType: string
  customerAnswerPageUrl: string
  customerAnswerPageImageSrc: string
}

export type CustomerContractsSearchFormPropsType = {
  folderId?: string
  searchParams: {
    folderIds: string[]
    name: string
    approverId: string
    customer: {
      id: string
      lastName: string
      firstName: string
    }
    status: typeof statusOptions[number]['value']
    createdAtStart: string
    createdAtEnd: string
  }
  folders: {
    id: string
    name: string
  }[]
  approvers: {
    id: string
    lastName: string
    firstName: string
  }[]
}

export type SearchFormType = {
  selectedFolders: {
    value: string
    label: string
  }[]
  name: string
  selectedApprover: {
    value: string
    label: string
  }
  selectedCustomer: {
    value: string
    label: string
  }
  statusOption: typeof statusOptions[number] | { value: ''; label: string }
  createdAtStart: string
  createdAtEnd: string
}

export const stepOptions = [
  { label: 'タイトル・PDF', value: 'basic_info' },
  { label: 'クライアント情報', value: 'customer_forms' },
  { label: '契約内容の入力', value: 'answer_item_forms' },
  { label: '署名欄の設定', value: 'invitee_field_forms' },
  { label: '送信内容の確認', value: 'confirmation' },
  { label: '署名の確認', value: 'signature_check' },
] as const
