import React, { useState } from 'react'
import { createRoot } from 'react-dom/client'

import { CustomerContractsEditPropsType, stepOptions } from './types'

import { toCamelCaseObjectFromSnakeCase } from '@/utils/toCamelCaseObjectFromSnakeCase'
import { TopStepperLayout } from '@/components/layouts'
import { StepType } from '@/components/layouts/components/Sidebar/types'
import {
  BasicInfo,
  CustomerForms,
  AnswerItemForms,
  DocumentFieldForms,
  Confirmation,
  SignatureCheck,
} from '@/components/features/CustomerContract/components'
import { useCustomerContractForm } from '@/components/features/CustomerContract/hooks/useCustomerContractForm'
import {
  isCustomerTypeQuestion,
  isCommonTypeQuestion,
} from '@/components/features/ContractSetting/hooks/useContractSettingForm'
import { Button } from 'react-bootstrap'

const CustomerContractEdit: React.FC<CustomerContractsEditPropsType> = (props) => {
  const {
    customerContract,
    customerContractRegister,
    customerContractControl,
    submitCustomerContract,
    isCustomerContractFormDirty,
    isSubmittingCustomerContract,
    customerContractErrors,
    refreshCustomerContract,
    setCustomerContractValue,
    documentFields,
    appendDocumentField,
    removeDocumentField,
    fieldLocations,
    appendFieldLocation,
    removeFieldLocation,
    customers,
    appendCustomer,
    removeCustomer,
    replaceCustomers,
    resetCustomerContractForm,
    deleteCustomerContract,
  } = useCustomerContractForm(props.id, props.contractSetting, props.folderId)
  const [steps, setSteps] = useState<StepType[]>(stepOptions.map((opt, i) => ({ ...opt, isCurrent: i === 0 })))
  const isDone = (key: string) => {
    const currentStep = steps.find((step) => step.isCurrent)
    if (key === currentStep?.value && isCustomerContractFormDirty) return false
    switch (key) {
      case 'basic_info':
        return (customerContract.name?.length || 0) > 0 && (customerContract.documents?.length || 0) > 0
      case 'customer_forms': {
        if (!props.contractSetting?.id) {
          return (
            (customerContract.contractInvitees?.filter((invitee) => invitee.inviteeType === 'Customer').length || 0) > 0
          )
        }
        const requiredCustomerQuestionItemIds: string[] =
          props.contractSetting?.questionItems
            .filter(
              (item) =>
                isCustomerTypeQuestion(item.answerType) && typeof item.customerOrder === 'number' && item.isRequired,
            )
            .map((item) => item.id) || []
        let isRequiredAnswersFilled = true
        requiredCustomerQuestionItemIds.forEach((requiredCustomerQuestionItemId) => {
          const requiredAnswerItem = customerContract.answerItems[requiredCustomerQuestionItemId]
          isRequiredAnswersFilled =
            isRequiredAnswersFilled &&
            (requiredAnswerItem?.byCustomer || !!requiredAnswerItem?.value || requiredAnswerItem?.value === 0)
        })
        return isRequiredAnswersFilled
      }
      case 'answer_item_forms': {
        const requiredQuestionItemIds: string[] =
          props.contractSetting?.questionItems
            .filter((item) => isCommonTypeQuestion(item.answerType) && item.isRequired)
            .map((item) => item.id) || []
        let isRequiredAnswersFilled = true
        requiredQuestionItemIds.forEach((requiredQuestionItemId) => {
          const requiredAnswerItem = customerContract.answerItems[requiredQuestionItemId]
          isRequiredAnswersFilled =
            isRequiredAnswersFilled &&
            (requiredAnswerItem?.byCustomer || !!requiredAnswerItem?.value || requiredAnswerItem?.value === 0)
        })
        return isRequiredAnswersFilled
      }
      case 'invitee_field_forms':
        return (
          (customerContract.contractInvitees?.length || 0) > 0 &&
          !customerContract.contractInvitees
            ?.filter((invitee) => invitee.inviteeType === 'Customer')
            .some(
              (invitee) =>
                invitee.documentFields.filter((field) => field.isRequired).length === 0 ||
                invitee.documentFields.some((field) => field.fieldLocations.length === 0),
            )
        )
      case 'confirmation':
        return customerContract.status !== 'draft'
      case 'signature_check':
        return customerContract.status === 'completed'
      default:
        return false
    }
  }
  const isDisabled = (key: string) => {
    switch (key) {
      case 'basic_info':
        return false
      case 'customer_forms':
        return !isDone('customer_forms') && !props.contractSetting && !isDone('basic_info')
      case 'answer_item_forms':
        return !isDone('answer_item_forms') && (!isDone('basic_info') || !isDone('customer_forms'))
      case 'invitee_field_forms':
        return (
          !isDone('invitee_field_forms') &&
          (!isDone('basic_info') || !isDone('customer_forms') || !isDone('answer_item_forms'))
        )
      case 'confirmation':
        return (
          !isDone('confirmation') &&
          (!isDone('basic_info') ||
            !isDone('customer_forms') ||
            !isDone('answer_item_forms') ||
            !isDone('invitee_field_forms'))
        )
      case 'signature_check':
        return !isDone('confirmation')
      default:
        return true
    }
  }

  return (
    <TopStepperLayout
      title="契約書"
      steps={steps.map((step) => ({
        ...step,
        isDone: isDone(step.value),
        isDisabled: isDisabled(step.value),
        isSkipped: !props.contractSetting?.id && step.value === 'answer_item_forms',
      }))}
      setCurrentStep={setSteps}
      afterStepChange={() => resetCustomerContractForm()}
      customMenu={
        props.permissionType === 'admin' &&
        ['draft', 'declined', 'expired', 'canceled'].includes(customerContract?.status || '') && (
          <div className="text-end">
            <Button variant="outline-danger" size="sm" onClick={deleteCustomerContract}>
              削除
            </Button>
          </div>
        )
      }
      containerFluid={steps.some((step) => ['invitee_field_forms'].includes(step.value) && step.isCurrent) || undefined}
    >
      {(() => {
        const currentStep = steps.find((step) => step.isCurrent)
        switch (currentStep?.value) {
          case 'basic_info':
            return (
              <BasicInfo
                {...props}
                customerContract={{
                  ...customerContract,
                  status: customerContract.status || 'draft',
                  documents: customerContract.documents || [],
                  contractInvitees: customerContract.contractInvitees || [],
                }}
                customerContractRegister={customerContractRegister}
                customerContractControl={customerContractControl}
                submitCustomerContract={submitCustomerContract}
                isCustomerContractFormDirty={isCustomerContractFormDirty}
                isSubmittingCustomerContract={isSubmittingCustomerContract}
                refreshCustomerContract={refreshCustomerContract}
              />
            )
          case 'customer_forms':
            return (
              <CustomerForms
                contractSetting={props.contractSetting}
                customerContract={{ ...customerContract, status: customerContract.status || 'draft' }}
                customerContractRegister={customerContractRegister}
                customerContractControl={customerContractControl}
                submitCustomerContract={submitCustomerContract}
                setCustomerContractValue={setCustomerContractValue}
                isCustomerContractFormDirty={isCustomerContractFormDirty}
                isSubmittingCustomerContract={isSubmittingCustomerContract}
                customerContractErrors={customerContractErrors}
                customers={customers}
                appendCustomer={appendCustomer}
                removeCustomer={removeCustomer}
                replaceCustomers={replaceCustomers}
              />
            )
          case 'answer_item_forms':
            return (
              <AnswerItemForms
                contractSetting={props.contractSetting}
                customerContract={{ ...customerContract, status: customerContract.status || 'draft' }}
                customerContractRegister={customerContractRegister}
                customerContractControl={customerContractControl}
                submitCustomerContract={submitCustomerContract}
                isCustomerContractFormDirty={isCustomerContractFormDirty}
                isSubmittingCustomerContract={isSubmittingCustomerContract}
              />
            )
          case 'invitee_field_forms':
            return (
              <DocumentFieldForms
                contractSetting={props.contractSetting}
                customerContract={{
                  ...customerContract,
                  status: customerContract.status || 'draft',
                  documents: customerContract.documents || [],
                  contractInvitees: customerContract.contractInvitees || [],
                }}
                customerContractRegister={customerContractRegister}
                customerContractControl={customerContractControl}
                submitCustomerContract={submitCustomerContract}
                isCustomerContractFormDirty={isCustomerContractFormDirty}
                isSubmittingCustomerContract={isSubmittingCustomerContract}
                setCustomerContractValue={setCustomerContractValue}
                documentFields={documentFields}
                appendDocumentField={appendDocumentField}
                removeDocumentField={removeDocumentField}
                fieldLocations={fieldLocations}
                appendFieldLocation={appendFieldLocation}
                removeFieldLocation={removeFieldLocation}
                refreshCustomerContract={refreshCustomerContract}
              />
            )
          case 'confirmation':
            return (
              <Confirmation
                contractSetting={props.contractSetting}
                customerContract={{
                  ...customerContract,
                  status: customerContract.status || 'draft',
                  isCustomerAnswering: customerContract.isCustomerAnswering || false,
                  documents: customerContract.documents || [],
                  contractInvitees: customerContract.contractInvitees || [],
                }}
                refreshCustomerContract={refreshCustomerContract}
                customerAnswerPageUrl={props.customerAnswerPageUrl}
                customerAnswerPageImageSrc={props.customerAnswerPageImageSrc}
              />
            )
          case 'signature_check':
            return (
              <SignatureCheck
                customerContract={{
                  ...customerContract,
                  status: customerContract.status || 'draft',
                  signedDocuments: customerContract.signedDocuments || [],
                  contractInvitees: customerContract.contractInvitees || [],
                }}
                currentUserId={props.currentUserId}
              />
            )
          default:
            return <></>
        }
      })()}
    </TopStepperLayout>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('customer-contracts-edit')
  if (node) {
    const data = node.dataset
    if (!data.props) return
    const props = toCamelCaseObjectFromSnakeCase<CustomerContractsEditPropsType>(JSON.parse(data.props))
    const formedCustomerContractEdit = createRoot(node)
    formedCustomerContractEdit.render(<CustomerContractEdit {...props} />)
  }
})
