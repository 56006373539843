import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Select from 'react-select'
import { Controller, useWatch, type UseFormRegister, type Control } from 'react-hook-form'

import { AnswerItemFormsPropsType, CustomerContractFormType } from '../../types'
import { isCommonTypeQuestion } from '@/components/features/ContractSetting/hooks/useContractSettingForm'
import type { QuestionItemType } from '@/components/features/QuestionItem/types'

export const AnswerItemForms: React.FC<AnswerItemFormsPropsType> = (props) => {
  return (
    <>
      <header className="row align-items-center justify-content-between sticky-top bg-white">
        <Col className="h4 mb-0 text-truncate">{props.customerContract?.name}</Col>
        <Col className="text-end">
          <Button
            variant="primary"
            onClick={props.submitCustomerContract}
            disabled={!props.isCustomerContractFormDirty || props.isSubmittingCustomerContract}
          >
            保存{!props.isCustomerContractFormDirty && '済み'}
            {props.isSubmittingCustomerContract && '中'}
          </Button>
        </Col>
      </header>
      <Row>
        <Col className="pb-5">
          <Form onSubmit={props.submitCustomerContract}>
            <Row className="gy-3 mt-3">
              {props.contractSetting?.questionItems
                .filter((questionItem) => isCommonTypeQuestion(questionItem.answerType))
                .map((questionItem) => (
                  <AnswerItemField
                    key={questionItem.id}
                    questionItem={questionItem}
                    customerContract={props.customerContract}
                    customerContractRegister={props.customerContractRegister}
                    customerContractControl={props.customerContractControl}
                  />
                ))}
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

type AnswerItemFieldPropsType = {
  questionItem: QuestionItemType
  customerContract: AnswerItemFormsPropsType['customerContract']
  customerContractRegister: UseFormRegister<CustomerContractFormType>
  customerContractControl: Control<CustomerContractFormType>
}

const AnswerItemField: React.FC<AnswerItemFieldPropsType> = ({
  questionItem,
  customerContract,
  customerContractRegister,
  customerContractControl,
}) => {
  const byCustomer = useWatch({
    control: customerContractControl,
    name: `answerItems.${questionItem.id}.byCustomer`,
  })
  switch (questionItem.answerType) {
    case 'date':
      return (
        <Col xs={12}>
          <Form.Label htmlFor={`answerItems.${questionItem.id}.value`}>
            <span>{questionItem.label}</span>{' '}
            {questionItem.isRequired && (
              <Badge bg={byCustomer ? 'dark' : 'danger'} className="align-text-bottom">
                必須
              </Badge>
            )}
          </Form.Label>
          <Form.Control
            {...customerContractRegister(`answerItems.${questionItem.id}.value` as const, {
              required: questionItem.isRequired && !byCustomer,
            })}
            id={`answerItems.${questionItem.id}.value`}
            type="date"
            required={questionItem.isRequired && !byCustomer}
            disabled={customerContract?.status !== 'draft'}
            className="mw-200px"
          />
          <Form.Check
            id={`answerItems.${questionItem.id}.byCustomer`}
            type="checkbox"
            label="送信先で入力"
            {...customerContractRegister(`answerItems.${questionItem.id}.byCustomer`)}
          />
        </Col>
      )
    case 'time':
      return (
        <Col xs={12}>
          <Form.Label htmlFor={`answerItems.${questionItem.id}.value`}>
            <span>{questionItem.label}</span>{' '}
            {questionItem.isRequired && (
              <Badge bg={byCustomer ? 'dark' : 'danger'} className="align-text-bottom">
                必須
              </Badge>
            )}
          </Form.Label>
          <Form.Control
            {...customerContractRegister(`answerItems.${questionItem.id}.value` as const, {
              required: questionItem.isRequired && !byCustomer,
            })}
            id={`answerItems.${questionItem.id}.value`}
            type="time"
            required={questionItem.isRequired && !byCustomer}
            disabled={customerContract?.status !== 'draft'}
            className="mw-200px"
          />
          <Form.Check
            id={`answerItems.${questionItem.id}.byCustomer`}
            type="checkbox"
            label="送信先で入力"
            {...customerContractRegister(`answerItems.${questionItem.id}.byCustomer`)}
          />
        </Col>
      )
    case 'textarea':
      return (
        <Col xs={12}>
          <Form.Label htmlFor={`answerItems.${questionItem.id}.value`}>
            <span>{questionItem.label}</span>{' '}
            {questionItem.isRequired && (
              <Badge bg={byCustomer ? 'dark' : 'danger'} className="align-text-bottom">
                必須
              </Badge>
            )}
          </Form.Label>
          <Form.Control
            {...customerContractRegister(`answerItems.${questionItem.id}.value` as const, {
              required: questionItem.isRequired && !byCustomer,
            })}
            id={`answerItems.${questionItem.id}.value`}
            as="textarea"
            type="textarea"
            required={questionItem.isRequired && !byCustomer}
            disabled={customerContract?.status !== 'draft'}
          />
          <Form.Check
            id={`answerItems.${questionItem.id}.byCustomer`}
            type="checkbox"
            label="送信先で入力"
            {...customerContractRegister(`answerItems.${questionItem.id}.byCustomer`)}
          />
        </Col>
      )
    case 'number':
      return (
        <Col xs={12}>
          <Form.Label htmlFor={`answerItems.${questionItem.id}.value`}>
            <span>{questionItem.label}</span>{' '}
            {questionItem.isRequired && (
              <Badge bg={byCustomer ? 'dark' : 'danger'} className="align-text-bottom">
                必須
              </Badge>
            )}
          </Form.Label>
          <InputGroup className="mw-200px">
            <Form.Control
              {...customerContractRegister(`answerItems.${questionItem.id}.value` as const, {
                required: questionItem.isRequired && !byCustomer,
              })}
              id={`answerItems.${questionItem.id}.value`}
              type="number"
              required={questionItem.isRequired && !byCustomer}
              disabled={customerContract?.status !== 'draft'}
            />
            <InputGroup.Text>{questionItem.unit}</InputGroup.Text>
          </InputGroup>
          <Form.Check
            id={`answerItems.${questionItem.id}.byCustomer`}
            type="checkbox"
            label="送信先で入力"
            {...customerContractRegister(`answerItems.${questionItem.id}.byCustomer`)}
          />
        </Col>
      )
    case 'single_select':
      return (
        <Col xs={12}>
          <Form.Label htmlFor={`answerItems.${questionItem.id}.selectedOptions`}>
            <span>{questionItem.label}</span>{' '}
            {questionItem.isRequired && (
              <Badge bg={byCustomer ? 'dark' : 'danger'} className="align-text-bottom">
                必須
              </Badge>
            )}
          </Form.Label>
          <Controller
            control={customerContractControl}
            name={`answerItems.${questionItem.id}.selectedOption`}
            render={({ field }) => (
              <Select
                options={questionItem.selectItemsText
                  ?.split(',')
                  .map((itemText) => ({ value: itemText, label: itemText }))}
                isDisabled={customerContract?.status !== 'draft'}
                {...field}
              />
            )}
            rules={{ required: questionItem.isRequired && !byCustomer }}
          />
          <Form.Check
            id={`answerItems.${questionItem.id}.byCustomer`}
            type="checkbox"
            label="送信先で入力"
            {...customerContractRegister(`answerItems.${questionItem.id}.byCustomer`)}
          />
        </Col>
      )
    case 'multi_select':
      return (
        <Col xs={12}>
          <Form.Label>
            <span>{questionItem.label}</span>{' '}
            {questionItem.isRequired && (
              <Badge bg={byCustomer ? 'dark' : 'danger'} className="align-text-bottom">
                必須
              </Badge>
            )}
          </Form.Label>
          <Row>
            {questionItem.selectItemsText?.split(',').map((itemText, i) => (
              <Col className="text-nowrap" key={i}>
                <Form.Check
                  {...customerContractRegister(`answerItems.${questionItem.id}.checkedOptions.${i}.isChecked`)}
                  inline
                  type="checkbox"
                  label={itemText}
                  disabled={customerContract?.status !== 'draft'}
                />
              </Col>
            ))}
          </Row>
          <Form.Check
            id={`answerItems.${questionItem.id}.byCustomer`}
            type="checkbox"
            label="送信先で入力"
            {...customerContractRegister(`answerItems.${questionItem.id}.byCustomer`)}
          />
        </Col>
      )
    case 'name':
    case 'name_kana':
      return (
        <React.Fragment>
          <Col xs={6}>
            <Form.Label htmlFor={`answerItems.${questionItem.id}.value`}>
              <span>{questionItem.label}</span>{' '}
              {questionItem.isRequired && (
                <Badge bg={byCustomer ? 'dark' : 'danger'} className="align-text-bottom">
                  必須
                </Badge>
              )}
            </Form.Label>
            <Form.Control
              {...customerContractRegister(`answerItems.${questionItem.id}.value` as const, {
                required: questionItem.isRequired && !byCustomer,
              })}
              id={`answerItems.${questionItem.id}.value`}
              type="text"
              required={questionItem.isRequired && !byCustomer}
              disabled={customerContract?.status !== 'draft'}
            />
            <Form.Check
              id={`answerItems.${questionItem.id}.byCustomer`}
              type="checkbox"
              label="送信先で入力"
              {...customerContractRegister(`answerItems.${questionItem.id}.byCustomer`)}
            />
          </Col>
          <Col xs={6}>
            <Form.Label htmlFor={`answerItems.${questionItem.id}.subvalue`}>{questionItem.sublabel}</Form.Label>
            <Form.Control
              {...customerContractRegister(`answerItems.${questionItem.id}.subvalue` as const, {
                required: questionItem.isRequired && !byCustomer,
              })}
              id={`answerItems.${questionItem.id}.subvalue`}
              type="text"
              required={questionItem.isRequired && !byCustomer}
              disabled={customerContract?.status !== 'draft'}
            />
          </Col>
        </React.Fragment>
      )
    default:
      return (
        <Col xs={12}>
          <Form.Label htmlFor={`answerItems.${questionItem.id}.value`}>
            <span>{questionItem.label}</span>{' '}
            {questionItem.isRequired && (
              <Badge bg={byCustomer ? 'dark' : 'danger'} className="align-text-bottom">
                必須
              </Badge>
            )}
          </Form.Label>
          <Form.Control
            {...customerContractRegister(`answerItems.${questionItem.id}.value` as const, {
              required: questionItem.isRequired && !byCustomer,
            })}
            id={`answerItems.${questionItem.id}.value`}
            type="text"
            required={questionItem.isRequired && !byCustomer}
            disabled={customerContract?.status !== 'draft'}
          />
          <Form.Check
            id={`answerItems.${questionItem.id}.byCustomer`}
            type="checkbox"
            label="送信先で入力"
            {...customerContractRegister(`answerItems.${questionItem.id}.byCustomer`)}
          />
        </Col>
      )
  }
}
