import React, { useState } from 'react'
import { useWatch, type Control, type UseFormRegister, type FieldErrorsImpl } from 'react-hook-form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

import { CustomerFormsPropsType, CustomerContractFormType } from '../../types'
import { isCustomerTypeQuestion } from '@/components/features/ContractSetting/hooks/useContractSettingForm'
import { CustomerSearchModal } from '@/components/features/Customer/components/CustomerSearchModal'
import type { CustomerType } from '@/components/features/Customer/types'
import type { QuestionItemType } from '@/components/features/QuestionItem/types'

const regexpPattern = (answerType: string) => {
  switch (answerType) {
    case 'email':
      return {
        value: /^([a-zA-Z0-9])+([a-zA-Z0-9._+-])*@((?:[-a-z0-9]+\.)+[a-z]{2,})$/,
        message: 'メールアドレスを正しく入力してください',
      }
    case 'name_kana':
      return {
        value: /^[ァ-ンヴー]*$/,
        message: '全角カタカナで入力してください',
      }
    default:
      return undefined
  }
}

export const CustomerForms: React.FC<CustomerFormsPropsType> = (props) => {
  const [showCustomerSearchModal, setShowCustomerSearchModal] = useState(false)
  const setCustomerValues = (customers: CustomerType[]) => {
    if (props.contractSetting?.questionItems.length) {
      customers.forEach((customer, index) => {
        const customerQuestionItems = props.contractSetting?.questionItems.filter(
          (item) => item.customerOrder === index,
        )
        customerQuestionItems?.forEach((questionItem) => {
          switch (questionItem.answerType) {
            case 'email':
              props.setCustomerContractValue(`answerItems.${questionItem.id}.value`, customer.email, {
                shouldDirty: true,
              })
              break
            case 'name':
              props.setCustomerContractValue(`answerItems.${questionItem.id}.value`, customer.lastName, {
                shouldDirty: true,
              })
              props.setCustomerContractValue(`answerItems.${questionItem.id}.subvalue`, customer.firstName, {
                shouldDirty: true,
              })
              break
            case 'name_kana':
              props.setCustomerContractValue(`answerItems.${questionItem.id}.value`, customer.lastNameKana, {
                shouldDirty: true,
              })
              props.setCustomerContractValue(`answerItems.${questionItem.id}.subvalue`, customer.firstNameKana, {
                shouldDirty: true,
              })
          }
        })
      })
    } else {
      props.customers?.forEach((_, index) => {
        const customer = customers[index]
        if (!customer) return

        props.setCustomerContractValue(`customers.${index}.email`, customer.email, { shouldDirty: true })
        props.setCustomerContractValue(`customers.${index}.lastName`, customer.lastName, { shouldDirty: true })
        props.setCustomerContractValue(`customers.${index}.firstName`, customer.firstName, { shouldDirty: true })
        props.setCustomerContractValue(`customers.${index}.lastNameKana`, customer.lastNameKana, { shouldDirty: true })
        props.setCustomerContractValue(`customers.${index}.firstNameKana`, customer.firstNameKana, {
          shouldDirty: true,
        })
        props.setCustomerContractValue(`customers.${index}.weddingDate`, customer.weddingDate, { shouldDirty: true })
      })
      props.replaceCustomers && props.replaceCustomers(customers)
    }
    setShowCustomerSearchModal(false)
  }
  const currentCustomers: CustomerType[] = []
  for (
    let i = 0;
    currentCustomers.length < (props.contractSetting?.customerInviteeNum || 0) &&
    i < (props.contractSetting?.customerInviteeNum || 0);
    i++
  ) {
    const customerQuestionItems = props.contractSetting?.questionItems.filter((item) => item.customerOrder === i)
    const customer: CustomerType = {
      id: '',
      email: '',
      lastName: '',
      firstName: '',
      lastNameKana: '',
      firstNameKana: '',
      weddingDate: '',
    }
    customerQuestionItems?.forEach((questionItem) => {
      const answerItem = props.customerContract?.answerItems[questionItem.id]
      if (!answerItem) return

      switch (questionItem.answerType) {
        case 'email':
          customer.email = answerItem.value?.toString() || ''
          break
        case 'name':
          customer.lastName = answerItem.value?.toString() || ''
          customer.firstName = answerItem.subvalue || ''
          break
        case 'name_kana':
          customer.lastNameKana = answerItem.value?.toString() || ''
          customer.firstNameKana = answerItem.subvalue || ''
          break
      }
    })
    if (
      customer.id ||
      customer.email ||
      customer.lastName ||
      customer.firstName ||
      customer.lastNameKana ||
      customer.firstNameKana
    ) {
      currentCustomers.push(customer)
    }
  }

  return (
    <>
      <CustomerSearchModal
        show={showCustomerSearchModal}
        setShow={setShowCustomerSearchModal}
        maxNum={props.contractSetting?.customerInviteeNum}
        submitSelectedCustomers={setCustomerValues}
        currentCustomers={props.contractSetting?.questionItems.length ? currentCustomers : props.customers || []}
      />
      <header className="row align-items-center justify-content-end sticky-top bg-white">
        <Col className="h4 mb-0 text-truncate">{props.customerContract?.name || props.contractSetting?.name}</Col>
        <Col className="text-end text-nowrap">
          <Button
            variant="outline-primary"
            className="mx-3"
            onClick={() => setShowCustomerSearchModal(true)}
            disabled={props.customerContract?.status && props.customerContract.status !== 'draft'}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} /> <span>クライアント選択</span>
          </Button>
          {!props.customerContract?.id ? (
            <Button
              variant="primary"
              onClick={props.submitCustomerContract}
              disabled={props.isSubmittingCustomerContract}
            >
              作成{props.isSubmittingCustomerContract && '中'}
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={props.submitCustomerContract}
              disabled={!props.isCustomerContractFormDirty || props.isSubmittingCustomerContract}
            >
              保存{!props.isCustomerContractFormDirty && '済み'}
              {props.isSubmittingCustomerContract && '中'}
            </Button>
          )}
        </Col>
      </header>
      <Row>
        <Col className="">
          <Form onSubmit={props.submitCustomerContract}>
            <Row className="gy-3 mt-0">
              {props.contractSetting?.questionItems
                .filter((questionItem) => isCustomerTypeQuestion(questionItem.answerType))
                .map((questionItem) => (
                  <CustomerFieldWithQuestionItem
                    key={questionItem.id}
                    questionItem={questionItem}
                    customerContract={props.customerContract}
                    customerContractRegister={props.customerContractRegister}
                    customerContractErrors={props.customerContractErrors}
                    customerContractControl={props.customerContractControl}
                  />
                ))}
              {!props.contractSetting?.questionItems.length && (
                <>
                  {props.customers?.map((_, index) => (
                    <React.Fragment key={index}>
                      <h6 className="mt-4">クライアント{index + 1}</h6>
                      <Col xs={12} className="mt-0">
                        <Form.Label htmlFor={`customers.${index}.email`}>
                          <span>メールアドレス</span>{' '}
                          <Badge bg="danger" className="align-text-bottom">
                            必須
                          </Badge>
                        </Form.Label>
                        <Form.Control
                          {...props.customerContractRegister(`customers.${index}.email`, {
                            required: true,
                            pattern: {
                              value: /^([a-zA-Z0-9])+([a-zA-Z0-9._+-])*@((?:[-a-z0-9]+\.)+[a-z]{2,})$/,
                              message: 'メールアドレスを正しく入力してください',
                            },
                          })}
                          id={`customers.${index}.email`}
                          type="text"
                          required
                          disabled={!!props.customerContract && props.customerContract.status !== 'draft'}
                        />
                        {props.customerContractErrors.customers &&
                          props.customerContractErrors.customers[index]?.email && (
                            <span role="alert" className="small text-danger">
                              ※{props.customerContractErrors.customers[index]?.email?.message}
                            </span>
                          )}
                      </Col>
                      <Col xs={6}>
                        <Form.Label htmlFor={`customers.${index}.lastName`}>
                          <span>姓</span>{' '}
                          <Badge bg="danger" className="align-text-bottom">
                            必須
                          </Badge>
                        </Form.Label>
                        <Form.Control
                          {...props.customerContractRegister(`customers.${index}.lastName`, { required: true })}
                          id={`customers.${index}.lastName`}
                          type="text"
                          required
                          disabled={!!props.customerContract && props.customerContract.status !== 'draft'}
                        />
                      </Col>
                      <Col xs={6}>
                        <Form.Label htmlFor={`customers.${index}.firstName`}>
                          <span>名</span>
                        </Form.Label>
                        <Form.Control
                          {...props.customerContractRegister(`customers.${index}.firstName`, { required: true })}
                          id={`customers.${index}.firstName`}
                          type="text"
                          required
                          disabled={!!props.customerContract && props.customerContract.status !== 'draft'}
                        />
                      </Col>
                      <Col xs={6}>
                        <Form.Label htmlFor={`customers.${index}.lastNameKana`}>
                          <span>セイ</span>
                        </Form.Label>
                        <Form.Control
                          {...props.customerContractRegister(`customers.${index}.lastNameKana`, {
                            pattern: {
                              value: /^[ァ-ンヴー]*$/,
                              message: '全角カタカナで入力してください',
                            },
                          })}
                          id={`customers.${index}.lastNameKana`}
                          type="text"
                          disabled={!!props.customerContract && props.customerContract.status !== 'draft'}
                        />
                        {props.customerContractErrors.customers &&
                          props.customerContractErrors.customers[index]?.lastNameKana && (
                            <span role="alert" className="small text-danger">
                              ※{props.customerContractErrors.customers[index]?.lastNameKana?.message}
                            </span>
                          )}
                      </Col>
                      <Col xs={6}>
                        <Form.Label htmlFor={`customers.${index}.firstNameKana`}>
                          <span>メイ</span>
                        </Form.Label>
                        <Form.Control
                          {...props.customerContractRegister(`customers.${index}.firstNameKana`, {
                            pattern: {
                              value: /^[ァ-ンヴー]*$/,
                              message: '全角カタカナで入力してください',
                            },
                          })}
                          id={`customers.${index}.firstNameKana`}
                          type="text"
                          disabled={!!props.customerContract && props.customerContract.status !== 'draft'}
                        />
                        {props.customerContractErrors.customers &&
                          props.customerContractErrors.customers[index]?.firstNameKana && (
                            <span role="alert" className="small text-danger">
                              ※{props.customerContractErrors.customers[index]?.firstNameKana?.message}
                            </span>
                          )}
                      </Col>
                      <Col xs={12}>
                        <Form.Label htmlFor={`customers.${index}.weddingDate`}>
                          <span>挙式日</span>
                        </Form.Label>
                        <Form.Control
                          {...props.customerContractRegister(`customers.${index}.weddingDate`)}
                          id={`customers.${index}.weddingDate`}
                          type="date"
                          disabled={!!props.customerContract && props.customerContract.status !== 'draft'}
                          className="mw-200px"
                        />
                        {props.customerContractErrors.customers &&
                          props.customerContractErrors.customers[index]?.weddingDate && (
                            <span role="alert" className="small text-danger">
                              ※{props.customerContractErrors.customers[index]?.weddingDate?.message}
                            </span>
                          )}
                      </Col>
                    </React.Fragment>
                  ))}
                  <Col>
                    <Card className="bg-light my-2">
                      <Card.Body
                        onClick={() =>
                          props.customerContract?.status === 'draft' &&
                          props.appendCustomer &&
                          props.appendCustomer({
                            id: '',
                            email: '',
                            lastName: '',
                            firstName: '',
                            lastNameKana: '',
                            firstNameKana: '',
                            weddingDate: '',
                          })
                        }
                        className={`btn ${props.customerContract?.status !== 'draft' ? 'disabled' : ''}`}
                      >
                        <h6 className="my-0 text-center">
                          <FontAwesomeIcon icon={faPlus} /> クライアントを追加
                        </h6>
                      </Card.Body>
                    </Card>
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

type CustomerFieldWithQuestionItemPropsType = {
  questionItem: QuestionItemType
  customerContract: CustomerFormsPropsType['customerContract']
  customerContractRegister: UseFormRegister<CustomerContractFormType>
  customerContractErrors: FieldErrorsImpl<CustomerContractFormType>
  customerContractControl: Control<CustomerContractFormType>
}

const CustomerFieldWithQuestionItem: React.FC<CustomerFieldWithQuestionItemPropsType> = ({
  questionItem,
  customerContract,
  customerContractRegister,
  customerContractErrors,
  customerContractControl,
}) => {
  const byCustomer = useWatch({
    control: customerContractControl,
    name: `answerItems.${questionItem.id}.byCustomer`,
  })
  return (
    <>
      <Col xs={questionItem.answerType === 'name' || questionItem.answerType === 'name_kana' ? 6 : 12}>
        {questionItem.customerOrder !== undefined && questionItem.answerType === 'email' && (
          <h6>クライアント{questionItem.customerOrder + 1}</h6>
        )}
        <Form.Label htmlFor={`answerItems.${questionItem.id}.value`}>
          <span>{questionItem.label}</span>{' '}
          {questionItem.isRequired && (
            <Badge bg={byCustomer ? 'dark' : 'danger'} className="align-text-bottom">
              必須
            </Badge>
          )}
        </Form.Label>
        <Form.Control
          {...customerContractRegister(`answerItems.${questionItem.id}.value`, {
            required: questionItem.isRequired && !byCustomer,
            pattern: regexpPattern(questionItem.answerType),
          })}
          id={`answerItems.${questionItem.id}.value`}
          type="text"
          required={questionItem.isRequired && !byCustomer}
          disabled={!!customerContract?.id && customerContract.status !== 'draft'}
        />
        <Form.Check
          id={`answerItems.${questionItem.id}.byCustomer`}
          type="checkbox"
          label="送信先で入力"
          {...customerContractRegister(`answerItems.${questionItem.id}.byCustomer`)}
        />
        {customerContractErrors.answerItems && customerContractErrors.answerItems[questionItem.id]?.value && (
          <span role="alert" className="small text-danger">
            ※{customerContractErrors.answerItems[questionItem.id]?.value?.message}
          </span>
        )}
      </Col>
      {(questionItem.answerType === 'name' || questionItem.answerType === 'name_kana') && (
        <Col xs={6}>
          <Form.Label htmlFor={`answerItems.${questionItem.id}.subvalue`}>{questionItem.sublabel}</Form.Label>
          <Form.Control
            {...customerContractRegister(`answerItems.${questionItem.id}.subvalue`, {
              required: questionItem.isRequired && !byCustomer,
              pattern: regexpPattern(questionItem.answerType),
            })}
            id={`answerItems.${questionItem.id}.subvalue`}
            type="text"
            required={questionItem.isRequired && !byCustomer}
            disabled={!!customerContract?.id && customerContract.status !== 'draft'}
          />
          {customerContractErrors.answerItems && customerContractErrors.answerItems[questionItem.id]?.subvalue && (
            <span role="alert" className="small text-danger">
              ※{customerContractErrors.answerItems[questionItem.id]?.subvalue?.message}
            </span>
          )}
        </Col>
      )}
    </>
  )
}
