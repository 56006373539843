import React, { useState, useCallback } from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { Fetch } from '@/utils/Fetch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'

import { AskCustomerToAnswerModalPropsType } from '../../types'

export const AskCustomerToAnswerModal: React.FC<AskCustomerToAnswerModalPropsType> = ({
  show,
  setShow,
  customerAnswerPageUrl,
  customerAnswerPageImageSrc,
  customerContract,
  refreshCustomerContract,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const switchCustomerAnswerPage = useCallback(async (isCustomerAnswering: boolean) => {
    setIsSubmitting(true)
    const res = await Fetch(`/api/v1/customer_contracts/${customerContract.id}`, 'PATCH', {
      customer_contract: { is_customer_answering: isCustomerAnswering },
    })
    if (res.ok) {
      refreshCustomerContract()
    } else {
      const json = await res.json()
      const errorMessage = json.errors?.join('\n')
      alert(errorMessage || `回答依頼ページの${isCustomerAnswering ? '発行' : '破棄'}に失敗しました`)
    }
    setIsSubmitting(false)
  }, [])
  return (
    <Modal show={show} onHide={() => setShow(false)} scrollable>
      <Modal.Header closeButton>回答依頼ページ{!customerContract.isCustomerAnswering && '発行'}</Modal.Header>
      <Modal.Body>
        {customerContract.isCustomerAnswering ? (
          <>
            <Row>
              <Col className="text-start">
                <a href={customerAnswerPageUrl} target="_blank" rel="noopener noreferrer">
                  {customerAnswerPageUrl}
                </a>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <img src={customerAnswerPageImageSrc} />
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button
                  variant="danger"
                  disabled={isSubmitting}
                  type="button"
                  onClick={() => switchCustomerAnswerPage(false)}
                >
                  回答依頼ページを破棄する
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col className="text-center">
                <FontAwesomeIcon icon={faQrcode} size="10x" />
              </Col>
            </Row>
            <Row>
              <Col className="text-center d-grid">
                <Button
                  variant="primary"
                  disabled={isSubmitting}
                  type="button"
                  onClick={() => switchCustomerAnswerPage(true)}
                >
                  回答依頼ページを発行する
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}
