import React, { useState, useEffect, useCallback } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { Fetch } from '@/utils/Fetch'

import { DocumentsTabDisplay } from '@/components/features/Document/components'

import { SignatureCheckPropsType, statusOptions, signatureStatusOptions } from '../../types'

type ContractInviteesStateType = {
  id: string
  inviteeType: 'User' | 'Customer'
  inviteeId: string
  inviteeLastName: string
  inviteeFirstName: string
  status?: typeof signatureStatusOptions[number]['value']
  inviteUrl?: string
}[]

export const SignatureCheck: React.FC<SignatureCheckPropsType> = (props) => {
  const status = statusOptions.find((opt) => opt.value === props.customerContract.status)
  const downloadPath = `/contracts/${props.customerContract.id}/signed_document_files/${
    props.customerContract.signedDocuments.length === 1 ? props.customerContract.signedDocuments[0].id : ''
  }`
  const [contractInvitees, setContractInvitees] = useState<ContractInviteesStateType>(
    props.customerContract?.contractInvitees || [],
  )

  const openSignPage = useCallback(
    async (contractInvitee: ContractInviteesStateType[number]) => {
      if (contractInvitee.inviteeId !== props.currentUserId) return
      if (contractInvitee.inviteUrl) {
        window.open(contractInvitee.inviteUrl, '_blank')
        return
      }
      const res = await Fetch(`/api/v1/contract_invitees/${contractInvitee.id}/invite`, 'GET', {})
      if (res.ok) {
        const json = await res.json()
        window.open(json.contractInvitee.inviteUrl, '_blank')
        setContractInvitees((prev) => [
          ...prev.map((invitee) => ({
            ...invitee,
            inviteUrl: invitee.inviteeId === props.currentUserId ? json.contractInvitee.inviteUrl : undefined,
          })),
        ])
      } else {
        alert('署名ページへのURLの取得に失敗しました。')
      }
    },
    [props.currentUserId],
  )

  const signatureStatusLabel = useCallback(
    (contractInvitee: ContractInviteesStateType[number]) => {
      return signatureStatusOptions.find((opt) => opt.value === contractInvitee.status)?.label || ''
    },
    [signatureStatusOptions],
  )

  useEffect(() => {
    setContractInvitees(props.customerContract.contractInvitees || [])
  }, [props.customerContract.contractInvitees])

  return (
    <>
      <header className="row align-items-center justify-content-end sticky-top bg-white z-1500">
        <Col className="h4 mb-0 text-truncate">{props.customerContract?.name}</Col>
      </header>
      <Row>
        <Col className="">
          <Row className="gy-2 mt-3 mx-0 border-bottom">
            <Col>契約ステータス</Col>
            <Col>{status?.label}</Col>
          </Row>
          <Table className="mt-4 mb-0">
            <thead>
              <tr>
                <th>署名者</th>
                <th>署名ステータス</th>
              </tr>
            </thead>
            <tbody>
              {contractInvitees.map((invitee) => (
                <tr key={invitee.id}>
                  <td>
                    {invitee.inviteeLastName}
                    {invitee.inviteeFirstName}
                  </td>
                  <td>
                    {props.currentUserId === invitee.inviteeId &&
                    invitee.status === 'pending' &&
                    status?.value === 'pending' ? (
                      <Button
                        onClick={() => {
                          openSignPage(invitee)
                        }}
                      >
                        {signatureStatusLabel(invitee)}
                      </Button>
                    ) : (
                      signatureStatusLabel(invitee)
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {status?.value === 'completed' && props.customerContract.signedDocuments.length > 0 && (
            <>
              <DocumentsTabDisplay documents={props.customerContract.signedDocuments} tabsClassName="pt-5" />
              <Row className="mt-2">
                <Col>
                  <Button variant="outline-primary" href={downloadPath}>
                    <FontAwesomeIcon icon={faDownload} className="me-1" />
                    署名済PDFダウンロード
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  )
}
